import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/about-layout.js";
import { Link } from 'gatsby';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Mis Proyectos Personales`}</h1>
    <p>{`A continuación hablaré de algunos proyectos personales en los cuales estoy trabajando o he trabajado. Estos proyectos han sido inspiración propia basado en las necesidades de algunos clientes y colaboradores. Todos son de mi propia creación y están protegidos bajo mi propiedad intelectual. Si te gustaría conocer más de ellos o formar parte de uno de ellos o inclusive invertir tus recursos no dudes en `}<Link to="/contactame" mdxType="Link">{`contactarme aquí`}</Link>{`.`}</p>
    <h2>{`Cotiza.me`}</h2>
    <div {...{
      "className": "bottom-spacing"
    }}>{`
  `}<span parentName="div" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "68.42105263157895%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAADCElEQVQ4y22Ra28aRxSG+ZP9B/3QL/0DiZR+aVU3alopqt24VRqaNG5rQ2q72JibwWAuBsx1uZnLclljjGEx7C4YWPNUu1WqxOqR3jlzzsx5Zs6MxXWaY+swjvVdALszzEEgzVEox2EwizOY/WjuCKRx+NPseVPsHMb59NGvfPL5JuuvXbyxHfPzby4s0vUA8bJHrS0hSl2k6xu6N0OuhjI9eWSqP1YYKBp9eUxvMEK6HlJvXRFOlPCFssSSAtF4jpPTcyziZZ+UUGLv4AiHy4vjyMOuw0mmWOFycEtXHlMs14hF4sSFMrF0nmA0QbZQo1LvkC/WTGDgNIXHH8fSurohLZTZ2rbhDYRM+Hm+SEXs0OhcIdRbnPmjCN8+R9r9nvF0gig28YfipIUayUyZaCJPIJzCawCNolK9SSIjkC6UyVfqlOotClURu9uK59xN0ndCadtGP3vGuFGnHD1m3+knGDXaLRA+y+EPJXEfx7BUW5eUak1ypQr50oUJNWCVpoQ/7CMU8bJv+5NCoUQsU+A0EsftDeA5DhM+yxKKZUyg7ySB22cAxTa+SJZHz+x88XyXRK6McNHg4qKKd38X68YGXz5+jO3tFtaNTV7/9JKX6y/47qs1flj7mr2dHfMznJ4IB0chLCmxTarZIVIROS3XSTc7ZDtdMi2Jw2CQN3+7WH9r5+mm1dQ3P75ibeMXnr6w8uqPbf7yBPCcC/hSAp5kHssKGKsqw5HMfLlAnU1RNI3+cMBc15nrS/TViltlYs4Xuo5hxp6JpjGdz1ne32NwDFmMRakjkcvmmBqbxmMGgwHFQgFVUcx4NpvRajaRZZmRLDObzqhVq9z0+8jDIYv53DxktVphMYaHNp/PuV+tTK+qKpPJhLu7O1RNQ1EUM/fQDI4J/DB4L6Oo1+vRbrcpFoumGvUajXqVZlM0O3hY898NHwKXyyW6rrNYLBiNRqZUVaElXVMVuwxvNbNY1+8/Ar03y4cJw2uaZrY0nU7Nt1PVfwHWdzE+e/I7VnuUxWLJWJn9L/AfdR3irHGIAZMAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Cotizame",
          "title": "Cotizame",
          "src": "/static/df6ee11cbec181657cb32f3dc0421a63/0a47e/cotizame-screen-medium.png",
          "srcSet": ["/static/df6ee11cbec181657cb32f3dc0421a63/1d8ec/cotizame-screen-medium.png 171w", "/static/df6ee11cbec181657cb32f3dc0421a63/41431/cotizame-screen-medium.png 343w", "/static/df6ee11cbec181657cb32f3dc0421a63/0a47e/cotizame-screen-medium.png 600w"],
          "sizes": "(max-width: 600px) 100vw, 600px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>
    </div>
    <p>{`Es un proyecto para ayudar a los independientes y pequeños negocios a crear y enviar cotizaciones y facturas, y recibir pagos por internet con facilidad. También permite llevar un control de los servicios y productos que vende, y crear inventarios. Adicionalmente permite tener un sitio para gestionar los contactos y relaciones con los clientes. Finalmente permite administrar proyectos y tareas. Es una aplicación que aún está en desarrollo y espero finalizar en el 2020. Puedes conocer más de ella en `}<a href="https://cotiza.me">{`Cotiza.me`}</a>{`. `}</p>
    <h2>{`Llámame Online`}</h2>
    <div {...{
      "className": "bottom-spacing"
    }}>{`
  `}<span parentName="div" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "68.42105263157895%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAADMElEQVQoz2OwVJ8Z6bwg1XdRjOs8R92JVmo91mqdVmrtViqtFspNRrJ1xnI1hjLltqqV9moVjhplXvqVdqqFZvI5ZvKZDJxq2838DlsFHtJy3iWgs5ZbfQW3+lJutcVcqgv41efoOC1Wt52r77Y0NGt7ePY2G//FopodgmotbHL17LI1DKJam0Q01gmorhJQXSaqsUREfZGI2nxh1TkCyjP0zBavWnxs8tR9Oxd1n5xReHnL7EXzN8npTpZWa+SVqeCXLWcQ1tygaLpJ3WqjivkaDas1auYrBFXmCqnO4lecKq43b17ehnn5m/MTSipDkzrTsme11E1bcCExcxWXVDmfbCmDkMY6NatNRi6bde3X6TmsNXRaLWewUFBlBp/CFA27+dtD6ja4FOVm9LUk1HaH9ExomrVy9u6QiKVc0mV8MkUMotrr1K03GDptNHBYa+i4Rtt6uZTuPGG1GYIqUzTMZnUUp6enTK/MOdJWuiUjaWlkzMqr/SmRXgVsUlVC8oUM3IrLpXRXqpqtljdYomK6VEZ3Ho/8VF6FSTzy/UwSnTMj3TOiVgTbffO0PKalVWBjkzMp2dzBNJRBoIBdNIPBJeywrd9OE5f1tv67zNzWGTiu0rVdrmu3zMBpqVPA5nLfbZkRp/ISHscF7IwJXhvst9Pdfb+d1zJ731l2PrMY/v//v3H7hdlrzqfltkxbuG/jxl37dmzbvWHdykVrnjx99/f//++//37/9ffnn/+///7/9x8K/oERSPOGVdvmVGRu7Mg/ceXBk0Pz/t1YdOP41r1rl95/+OLHt1+vXn16/frL02cfPn7+BtbyF45AmmdNWFZXnhewaWJfe9mKZdNPbFiQUbMjv2LZ0ycvPnz8+eDR+yfPPj578enl6y/v3n//9+8/HIE01zaukvOocZ5bdao+s7WouLKlNTJpQnRyz/Nnr99//PHg0bsnzz4+evLh/Yfv//7BHQ4CIM2NTQuktYK0A6KLQrNTXeN8Q9ItDN29XOOeP3/3/cefN2+/fPj4/d37b5+//PiPCkCa79x9un/f+YP7LhpbFLHyBPX1rztx8vrhw5d//vz1Hy8AAJ+u0JsMGaBSAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Cotizame",
          "title": "Cotizame",
          "src": "/static/6e5343d2b9081496f188008461e5e1a1/0a47e/llamame-screen-medium.png",
          "srcSet": ["/static/6e5343d2b9081496f188008461e5e1a1/1d8ec/llamame-screen-medium.png 171w", "/static/6e5343d2b9081496f188008461e5e1a1/41431/llamame-screen-medium.png 343w", "/static/6e5343d2b9081496f188008461e5e1a1/0a47e/llamame-screen-medium.png 600w"],
          "sizes": "(max-width: 600px) 100vw, 600px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>
    </div>
    <p>{`Llámame Online es un proyecto de mi amigo Roberto Rubio para su empresa `}<a href="https://servidoresrapidos.net">{`ServidoresRapidos.net`}</a>{`. Está basado en una plataforma gratuita y de código abierto para hacer llamadas y conferencias en línea llamada Jitsi Meet. Está instalado en un servidor dentro de ServidoresRapidos.net y la plantilla fue personalizada por mi. Igualmente participé en la página de inicio (landing page) del sitio. El producto es gratuito, pero tiene una versión de pago el cual incluye la posibilidad de tener tu propio software de video conferencias (a la Zoom) pero con tu propia marca y nombre. Puedes conocer más del proyecto en `}<a href="https://llamame.online">{`Llamame.Online`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      